html body {
    background-color: #f8f8ff;
}

.desH1 {
    font-size: 3em;
    text-align: center;
    margin: 60px;
    font-family: 'Silkscreen', sans-serif;
}

.desP {
    font-size: 1.5em;
    text-align: center;
    margin: 60px;
    font-family: Impact;
}

.searchBar {
    text-align: center;
    margin: 60px;
    
}

.searchBar input {
    width: 500px; /* Set the width to your desired size */
    height: 40px; /* Set the height to your desired size */
    padding: 10px; /* Add padding as needed for spacing */
    font-size: 16px; /* Adjust the font size */
    text-align: center; /* Align the text to the center */
}

.gameName {
    font-size: 1.5em;
    text-align: center;
    margin: 60px;
    font-family: Impact;
    
}

.listedGames li .gameName:hover,
.listedGames li .episodeNumber:hover {
  /* Add styles for the hover state */
  color: aqua; /* Change text color on hover */
  text-decoration: underline; /* Add underline on hover, or adjust as needed */
  cursor: pointer;
}

.gameDetails {
    font-size: 1.5em;
    text-align: center;
    margin: 60px;
    font-family: Impact;
    border: 2px solid black;
    background-color: lightskyblue;
    border-radius: 20px;
}

.listedGames ul {
    list-style-type: none; /* Remove the default list item marker */
  }

  @media screen and (min-width: 300px) and (max-width: 750px) {
    .searchBar {
        text-align: center;
        margin: 60px;
    }
    .searchBar input {
        width: 200px; /* Set the width to your desired size */
        height: 40px; /* Set the height to your desired size */
        padding: 10px; /* Add padding as needed for spacing */
        font-size: 16px; /* Adjust the font size */
        text-align: center; /* Align the text to the center */
    }
  }
  