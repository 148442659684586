.welcome {
    font-size: 2em;
    text-align: center;;
    font-family: 'Silkscreen', sans-serif;;
}

.whatIsH2 {
    font-size: 2em;
    text-align: center;
    margin: 60px;
    font-family: 'Silkscreen', sans-serif;;
}

.whatIsP {
    font-size: 1.5em;
    text-align: center;
    margin: 60px;
    font-family: Impact;
}

.whereToFindH2 {
    font-size: 2em;
    text-align: center;
    margin: 60px;
    font-family: 'Silkscreen', sans-serif;
}

.whereToFindP {
    font-size: 1.5em;
    text-align: center;
    margin: 60px;
    font-family: Impact;
}

.socialMediaH2 {
    font-size: 2em;
    text-align: center;
    margin: 60px;
    font-family: 'Silkscreen', sans-serif;
}

.socialMediaP {
    font-size: 1.5em;
    text-align: center;
    margin: 60px;
    font-family: Impact;
}

html {
    background-color: #f8f8ff;
}

.homePage {
    background-color: #f8f8ff;
}