
.logo {
  width: 100%;
  height: auto;
  display: flex;
  background-color: #f2f3f4;
  color: black;
  margin: 0; /* Remove any margin */
  padding: 0; /* Remove any padding */
}


  
  
  .navigation {
      width: 100%;
      height: auto;
      display: flex;
      background-color: #f2f3f4;
      align-content: center;
      color: black;
      justify-content: center;
    }
    .navbar-toggler {
      border: none;
      background-color: transparent;
    }
    
    .navbar-collapse {
      display: flex;
      padding: 0;
    }
    .headerContainer {
      max-width: 1200px; /* Adjust the value as needed */
      margin: 0 auto; /* Center the container */
      padding: 20px; /* Add padding for spacing if desired */
      background-color: #ffffff; /* Set a background color if needed */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a box shadow for a card-like effect */
      background-image: "../Images/soul-header.png";
    }
    
  
    
    .navbar-nav {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .nav-item {
      margin-bottom: 10px;
    }
    
    .nav-link {
      color: black;
      text-decoration: none;
    }
    
    /* Styles for individual buttons */
    .navbar-brand {
      size: cover;
    }
    
    /* Styles for the header image */
    .img-responsive {
      width: 70%;
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;

    }
    
  
    
    @media screen and (min-width: 300px) and (max-width: 750px) {
      .img-responsive {
         width: 60%;
      }
   
      .navbar-collapse {
         display: flex; /* or display: block; depending on your design */
         padding: 0;
      }
   
   
    
      
    }
    
    